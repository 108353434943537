import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router";
// import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import jwt_decode from "jwt-decode";
import "react-datepicker/dist/react-datepicker.css";

import {
  ProtectedRoute,
  Sidebar,
  Topbar,
  NotFound,
} from "@vms-admin/components";
import { protectedRoutes, routes } from "./routes";
// import { fetchSidebarItems } from "./redux/actions";
import API_URL from "./api/api.constants";
import { Toaster } from "react-hot-toast";
import axios from "axios";

const App = () => {
  const accessToken = localStorage.getItem("access-token");
  const [sidebarItems, setSidebarItems] = useState([]);
  const [instance, setInstance] = useState("");
  // const sidebarItems = useSelector(({ sidebar }) => sidebar.sidebarItems);
  const history = useHistory();
  // const dispatch = useDispatch();
  const location = useLocation();

  const tokenData = useMemo(
    () => accessToken && jwt_decode(accessToken),
    [accessToken]
  );

  const checkTokenValidity = useCallback(() => {
    const expirationTime = tokenData?.exp * 1000 - 60000;
    if (Date.now() >= expirationTime) {
      localStorage.clear();
      history.push("/");
      return "not valid";
    }
    return "valid";
  }, [tokenData, history]);

  const toggleBurgerMenu = useCallback(
    () => document.querySelector("body").classList.toggle("open_menu"),
    []
  );

  const showUserUI = useMemo(
    () =>
      location.pathname !== "/" &&
      location.pathname !== "/change-password" &&
      location.pathname !== "/resend-confirmation" &&
      location.pathname !== "/confirmation-successful" &&
      location.pathname !== "/register" &&
      location.pathname !== "/email-sent" &&
      !location.pathname.includes("confirm-email"),
    [location.pathname]
  );

  useEffect(() => checkTokenValidity(), [checkTokenValidity]);
  useEffect(() => {
    if (location.pathname !== "/") {
      axios
        .get(API_URL.dashboard)
        .then((res) => res?.status === 200 && setSidebarItems(res.data?.menu))
        .catch((error) => console.log("error", error));
    }
  }, [accessToken]);

  useEffect(() => {
    if (window.location.href.includes("felix")) {
      setInstance("felix");
    }
    if (window.location.href.includes("incontrol")) {
      setInstance("in-control");
    }
    if (window.location.href.includes("rokit")) {
      setInstance("rokit");
    }
    if (window.location.href.includes("afritech")) {
      setInstance("starlink");
    }
  }, []);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    if (instance === "felix") {
      favicon.href = "felix-favicon.ico";
    }
    if (instance === "in-control") {
      favicon.href = "in-favicon.ico";
    }
    if (instance === "rokit") {
      favicon.href = "rokit-favicon.ico";
    }
    if (instance === "starlink") {
      favicon.href = "starlink-favicon.ico";
    }
  }, [instance]);

  // useEffect(() => dispatch(fetchSidebarItems()), [dispatch]);

  return (
    <div className={`${instance}`}>
      <div className="dashboard_container">
        {showUserUI && <Topbar />}
        {showUserUI && <Sidebar items={sidebarItems} />}
        {showUserUI && (
          <div className="burger_menu" onClick={toggleBurgerMenu}></div>
        )}
        <Toaster
          toastOptions={{
            className: "kmmrce-toaster",
            duration: "20000000",
            success: {
              style: {
                background: "#63DA99",
                color: "#FFF",
              },
              iconTheme: {
                primary: "#FFF",
                secondary: "#3Cd17F",
              },
            },
            error: {
              style: {
                background: "#FF9999",
                color: "#FFF",
              },
            },
          }}
        />
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={location.pathname}>
            {routes.map(({ path, component }) => (
              <Route exact path={path} component={component} />
            ))}

            {protectedRoutes.map(({ path, component }) => (
              <ProtectedRoute exact path={path} component={component} />
            ))}
            <Route component={NotFound} />
          </Switch>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default App;
