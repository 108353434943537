import styled from "styled-components";

export const UploadWrapper = styled.div`
  display: flex;
`;
export const UploadField = styled.div`
  position: relative;
  max-width: 100%;
  height: 150px;
  border: ${(props) =>
    props.hasError ? `1px dashed #ffb2b2 ` : `1px dashed #eaeaea`};
  border-radius: 7px;
  margin-bottom: ${(props) => props.theme.space[3]}px;
  margin-top: 10px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    border-color: ${(props) => (props.hasError ? `#ffb2b2 ` : `#d7d7da`)};
  }
`;

export const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  background-color: #14181a;
  padding: ${(props) => props.theme.space[2]}px;
  margin-top: 16px;
  border-radius: 7px;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  /* background: rgba(255, 255, 255, 0.5); */
`;
export const Icon = styled.div`
  margin-bottom: ${(props) => props.theme.space[2]}px;
`;
export const Text = styled.span`
  color: #333;
  width: 217px;
  color: #8e8e8e;
  text-align: center;
  font-size: 14px;
`;

export const UploadedImg = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 5px;
  margin-right: 10px;
`;

export const ImgInfo = styled.div`
  display: flex;
  align-items: center;
`;
