import React, { useState, useEffect, useCallback, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  KMMRCELogo,
  Notification,
  ChevronDown,
  Chat,
  INControl,
} from "@vms-admin/icons";
import { useClickOutside } from "@vms-admin/hooks";
import { fetchUserInfo, logoutUser, setUserRole } from "../../redux/actions";
export const Topbar = () => {
  const targetEl = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [instance, setInstance] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const handleDropdown = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const userRole = useSelector(({ auth }) => auth.role);

  const handleLogout = () =>
    dispatch(logoutUser()).then(() => history.push("/"));

  useClickOutside(targetEl, handleDropdown);

  useEffect(() => {
    window.onscroll = () =>
      window.pageYOffset > 80 ? setScrolled(true) : setScrolled(false);
  }, [setScrolled]);

  useEffect(() => dispatch(setUserRole(userInfo?.role)), [dispatch, userInfo]);

  useEffect(() => dispatch(fetchUserInfo()), [dispatch]);

  useEffect(() => {
    if (window.location.href.includes("felix")) {
      setInstance("felix");
    }
    if (window.location.href.includes("incontrol")) {
      setInstance("in-control");
    }
    if (window.location.href.includes("rokit")) {
      setInstance("rokit");
    }
    if (window.location.href.includes("afritech")) {
      setInstance("starlink");
    }
  }, []);

  return (
    <header className="dashboard_navbar">
      <div className="burger_menu_overlay"></div>
      <div className="kmmrce_logo">
        {instance === "felix" && (
          <img
            src="/images/felix/inverse-logo.svg"
            width="50"
            alt="felix logo"
          />
        )}
        {instance === "in-control" && <INControl />}
        {instance === "rokit" && (
          <img src="/images/rokit-logo.png" width="120" alt="rokit logo" />
        )}
        {!instance && <KMMRCELogo />}
      </div>

      <div
        id="top_nav_bar"
        className={`flex justify ${scrolled ? "scrollUp" : ""}`}
      >
        <div className="left"></div>
        <div className="right flex center">
          <a href="/" className="top_nav_btn hide_mobile">
            <Chat />
          </a>
          <a href="/" className="top_nav_btn has_notification">
            <Notification />
          </a>
          <div
            className="nav-item dropdown"
            style={{ position: "relative" }}
            onClick={handleDropdown}
          >
            <a
              className="name_tag inner-menu-toggle"
              href="/"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={(e) => e.preventDefault()}
            >
              {userInfo?.image ? (
                <img
                  src={userInfo.image}
                  alt={userInfo.email}
                  style={{ width: "26px", marginRight: "8px" }}
                />
              ) : (
                <figure style={{ background: "#4B42AE" }}>
                  {userInfo?.email?.charAt(0).toUpperCase()}
                </figure>
              )}

              <span className="hide_mobile">{userInfo?.email}</span>
              <ChevronDown />
            </a>
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  className={`inner-menu ${isOpen && `active`}`}
                  ref={targetEl}
                  initial={{ y: -20, opacity: 0.3 }}
                  exit={{ y: -20, opacity: 0.3 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <ul className="inner-menu-li">
                    <li className="inner-menu-item">
                      <Link to="/account-settings">Account Settings</Link>
                    </li>
                    {!userRole.is_admin && (
                      <li className="inner-menu-item">
                        <Link to="/profile">Profile</Link>
                      </li>
                    )}
                    <li className="inner-menu-item" onClick={handleLogout}>
                      Logout
                    </li>
                  </ul>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Topbar;
