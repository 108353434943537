import React, { useState, useCallback, useRef, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

import usePlacesAutoComplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { Search } from "@vms-admin/icons";

import * as Styled from "./map.style";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const libraries = ["places"];

export const Map = ({
  addressInfo,
  address,
  userLat,
  userLng,
  disabled,
  noAddressField,
}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDExax1_3uobjt6iIS7SMxsQUXJDbd9DA0",
    libraries,
  });

  const [marker, setMarker] = useState({});
  const [places, setPlaces] = useState([]);
  const [center, setCenter] = useState(null);
  const mapRef = useRef();
  const onMapLoad = React.useCallback((map) => (mapRef.current = map), []);

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(15);
  }, []);

  const handleMapClick = useCallback((e) => {
    if (addressInfo) {
      const { lat, lng } = e.latLng;
      setMarker({
        lat,
        lng,
        time: new Date(),
      });

      getGeocode({
        location: {
          lat,
          lng,
        },
      })
        .fromLatLng(e.latLng.lat(), e.latLng.lng())
        .then(
          (response) => {
            console.log(response);
            const address = response[0].formatted_address;
            addressInfo?.(address, lat, lng);
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }, []);

  useEffect(() => {
    if (navigator && !userLat && !userLng) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          lat: Number(position.coords.latitude),
          lng: Number(position.coords.longitude),
        });
        setMarker(() => ({
          lat: Number(position.coords.latitude),
          lng: Number(position.coords.longitude),
          time: new Date(),
        }));
      });
    } else if (userLat && userLng) {
      setCenter({
        lat: Number(userLat),
        lng: Number(userLng),
      });
      setMarker(() => ({
        lat: Number(userLat),
        lng: Number(userLng),
        time: new Date(),
      }));
      mapRef?.current?.setZoom(12);
    }
  }, [userLat, userLng]);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutoComplete({
    requestOptions: {
      location: {
        lat: () => -3.745,
        lng: () => -38.523,
      },
      radius: 200 * 1000,
      debounce: 300,
    },
  });

  useEffect(() => {
    setValue(address ? address : "");
  }, [address, setValue]);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <Styled.Map>
      {!noAddressField && (
        <div>
          <Combobox
            className="input_holder"
            onSelect={async (address) => {
              setValue(address, false);
              clearSuggestions();
              try {
                const result = await getGeocode({ address });
                const { lat, lng } = await getLatLng(result[0]);
                addressInfo(address, lat, lng);
                panTo({ lat, lng });
                setMarker({
                  lat,
                  lng,
                });
              } catch (error) {
                console.log("Error", error);
              }
            }}
          >
            <div style={{ position: "relative" }}>
              <ComboboxInput
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  setPlaces(data);
                }}
                disabled={!ready || disabled}
                autocomplete={false}
                placeholder="Search your location"
                style={{
                  width: "100%",
                  border: "1px solid #EAEAEA",
                  borderRadius: 7,
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingLeft: 40,
                  marginBottom: 10,
                  height: 55,
                  backgroundColor: "#000",
                }}
              />
              <span
                style={{
                  position: "absolute",
                  left: 16,
                  top: 20,
                }}
              >
                <Search />
              </span>
            </div>
            {places?.length !== 0 && (
              <ComboboxPopover
                style={{
                  border: "1px solid #eaeaea",
                }}
              >
                <ComboboxList>
                  {status === "OK" &&
                    places.map(({ id, description }) => (
                      <ComboboxOption
                        key={id}
                        value={description}
                        style={{
                          padding: "16px",
                          borderBottom: "1px solid #eaeaea",
                          color: "#333",
                        }}
                      ></ComboboxOption>
                    ))}
                </ComboboxList>
              </ComboboxPopover>
            )}
          </Combobox>
        </div>
      )}
      {/* <span
        onClick={() =>
          navigator &&
          navigator.geolocation.getCurrentPosition(
            (position) => {
              panTo({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            },
            () => null
          )
        }
      >
        locate Me!
      </span> */}
      <div style={{ borderRadius: 7, overflow: "hidden" }}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lat: center?.lat,
            lng: center?.lng,
          }}
          zoom={10}
          // onClick={addressInfo && handleMapClick}
          onLoad={onMapLoad}
          options={{
            clickableIcons: false,
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
          }}
        >
          <Marker
            key={marker?.time?.toISOString()}
            position={{ lat: marker.lat, lng: marker.lng }}
          />
          {/* {selected ? (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.lng }}
            onCloseClick={() => setSelected(null)}
            anchor={marker}
          >
            <p>Spotted</p>
          </InfoWindow>
        ) : null} */}
        </GoogleMap>
      </div>
    </Styled.Map>
  );
};
